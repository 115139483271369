/* eslint-disable */
import $ from 'jquery';
import 'intersection-observer/intersection-observer';

export default function () {
  const myImgs = document.querySelectorAll('.section');
  const config = {
    rootMargin: '100px 0px',
    // threshold: [0, 0.25, 0.75, 1]
  };

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        //console.log('in the view');
        $(entry.target).addClass('visible');
        observer.unobserve(entry.target);
      } else {
        //console.log('out of view');
      }
    });
  }, config);

  myImgs.forEach(image => {
    observer.observe(image);
  });

  // observer.disconnect()
}
