/* eslint-disable */
import navigation from './components/layout/navigation';
import responsiveVideos from './components/layout/responsiveVideos';
import validation from './components/forms/validation';
import RecaptchaForm from './components/forms/RecaptchaForm';
import gaTracking from './components/tracking/gatracking';
import headerScroll from './components/layout/headerScroll';
import slickSlider from './components/layout/slickSlider';
import categoryFilter from './components/layout/categoryFilter';
import mobileMenu from './components/layout/mobileMenu';
import intersectionObserver from './components/layout/intersectionObserver';
import Contact from './pages/Contact';

var objectFitImages = require('object-fit-images');

import '@fancyapps/fancybox/dist/jquery.fancybox.min';

require('./utils/nativeConsole');
require('./utils/polyfillForEach');

// require('jquery-touchswipe/jquery.touchSwipe'); // use with fancybox, cycle2, etc



// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');
// Layout setup
navigation();
responsiveVideos();

// Forms
validation();

// Tracking
gaTracking();
headerScroll();
slickSlider();
categoryFilter();
mobileMenu();
intersectionObserver();

if($('#form-contact').length){
  // Recaptcha submit handler for each form
  const contactForm = new RecaptchaForm('#form-contact');
  window.submitRecaptchaFormContact = () => {
    contactForm.submitCallback();
  };
}

$('[data-fancybox]').fancybox({
  toolbar  : false,
  smallBtn : true,
  iframe : {
    preload : false
  }
})

$('[data-fancybox="client_gallery"]').fancybox({
  // Options will go here
});

const someImages = $('.square__image img', '.header__image img', '.bleed__image img', '.footer__image img', '.category__square img', '.article__image img');
objectFitImages();

$(document).ready(function () {
  if($(window).outerWidth() > 768) {
    $('.main').css('padding-bottom', `${$('.page-footer').outerHeight(true)}px`);
    $('.page-footer').css('margin-top', `-${$('.page-footer').outerHeight(true)}px`);
  }
})

$(window).resize(function () {
  if($(window).outerWidth() > 768){
    $('.main').css('padding-bottom', `${$('.page-footer').outerHeight(true)}px`);
    $('.page-footer').css('margin-top', `-${$('.page-footer').outerHeight(true)}px`);
  } else {
    $('.main').css('padding-bottom', '0px');
    $('.page-footer').css('margin-top', '0px');
  }
});

$('.js--scroll-to').click(function (e) {
  const offset = $($(e.currentTarget).attr('href')).offset().top;
  const header = ($(window).outerWidth(true) > 767) ? 105 : 0;
  e.preventDefault();
  $('html, body').animate({
    scrollTop: offset - header
  }, 1500);
});

$('.category__square-flex').on('mouseover', function (e) {
  $(e.currentTarget).find('.slide-in__text').slideToggle('fast');
});
$('.category__square-flex').on('mouseout', function (e) {
  $(e.currentTarget).find('.slide-in__text').slideToggle('fast');
});

const newsletterForm = new RecaptchaForm('#form-newsletter');
window.submitRecaptchaFormNewsletter = () => {
  newsletterForm.submitCallback();
};

const courseForm = new RecaptchaForm('#form-course');
window.submitRecaptchaFormCourse = () => {
  courseForm.submitCallback();
};

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Contact,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    // Exceptional use of new
    // eslint-disable-next-line no-new
    new pages[pageClassName]();
  }
}
