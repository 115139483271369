/* eslint-disable */
import $ from 'jquery';

export default function () {
  if ($(window).scrollTop() > 40) {
    $('.page-header').addClass('scrolled');
  }
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 40) {
      $('.page-header').addClass('scrolled');
    } else {
      $('.page-header').removeClass('scrolled');
    }
  });
}
