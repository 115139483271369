/* eslint-disable */

import $ from 'jquery';

export default function () {
  const external = [];
  const mails = [];
  const files = [];
  const trackbtns = [];
  const filetypes = ['pdf', 'jpg'];
  const links = $('a');
  const lLength = links.length;
  for (let i = 0; i < lLength; i += 1) {
    const $elem = $(links[i]);
    const href = $elem.attr('href');

    if (href !== undefined) {
      if ((href.match(/^https?\:/i)) && (!href.match(document.domain))) {
        external.push(links[i]);
      }
      else if (href.match(/^mailto\:/i)) {
        mails.push(links[i]);
      }
      else if ($.inArray(href.split('.')[href.split('.').length - 1], filetypes) >= 0) {
        files.push(links[i]);
      }
      else if ($elem.hasClass('tracking')) {
        trackbtns.push(links[i]);
      }
    }
  }

  $(external).on('click', function () {
    const link = $(this);
    const linktxt = (link.attr('title') !== '') ? link.attr('title') + ' [' + link.attr('href').replace(/^https?\:\/\//i, '') + ']' : link.attr('href').replace(/^https?\:\/\//i, '');
    ga('send', 'event', 'external', 'visit', linktxt);
  });

  $(mails).on('click', function () {
    const email = $(this);
    const emailtxt = email.attr('href').substring(7);
    ga('send', 'event', 'mailto', 'click', emailtxt);
  });

  $(files).on('click', function () {
    const file = $(this);
    const filehref = file.attr('href');
    const fileext = filehref.split('.')[filehref.split('.').length - 1];
    const filename = filehref.substring(filehref.lastIndexOf('/') + 1);
    ga('send', 'event', 'download', fileext, filename);
  });

  $(trackbtns).on('click', function () {
    const link = $(this);
    const txt = (link.data('title') !== 'undefined') ? link.data('title') : link.attr('href').replace(/^https?\:\/\//i, '');
    ga('send', 'event', 'button', 'click', txt);
  });
}
