import $ from 'jquery';

export default function () {
  if ($('.filter__tags').length >= 1) {
    const url = window.location.href.split('?category=')[1];
    if (url !== undefined) {
      $('.not-found').css('display', 'none');
    }
    $('.filter__tags a').click(function (e) {
      e.preventDefault();
      const searchTerm = $(this).attr('href').replace('#', '');
      const url2 = (window.location.href.indexOf('?category=') > 0) ? window.location.href.split('?category=')[0] : window.location.href.split('/p')[0];
      if (searchTerm !== 'all') {
        window.location.href = `${url2}?category=${searchTerm}`;
      } else {
        window.location.href = url2;
      }
    });
  }
}
