
import BasicGoogleMap from '../components/maps/BasicGoogleMap';

export default class Contact {
  constructor() {
    // Map
    const map = new BasicGoogleMap();
    map.init();
  }
}
