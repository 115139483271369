/* eslint-disable */
import $ from 'jquery';
import 'modaal/dist/js/modaal';

export default function () {
  $('.main-nav__trigger').modaal({
    after_open: function () {
      $('body').css('overflow', 'hidden');
      $('body').css('height', '100vh');
      $('body').css('max-height', 'calc(' + window.innerHeight * 0.01 + 'px * 100)');
    },
    after_close: function () {
      $('body').css('overflow', 'visible');
      $('body').css('max-height', 'auto');
      $('body').css('height', 'auto');
    },
  });
  // $('.main-nav__trigger').click(function (e) {
  //   setTimeout(function () {
  //     $('.modaal-wrapper').find('.mobile__nav').addClass('in');
  //   }, 10);
  // })
  // $('.modaal-close').click(function (e) {
  //   $('.modaal-wrapper').find('.mobile__nav').removeClass('in');
  // })
}
